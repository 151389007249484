import React, { useState } from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import fbTrack from "../../custom/fbTrack"
const axios = require("axios")

const Mapa = ({ id }) => {
  const [response, setResponse] = useState("")

  function doSubmit(e) {
    e.preventDefault()
    var formData = new FormData()
    formData.append("name", document.querySelector("#nomeMSG").value)
    formData.append("email", document.querySelector("#emailMSG").value)
    formData.append("phone", document.querySelector("#telefoneMSG").value)
    formData.append("subject", document.querySelector("#assuntoMSG").value)
    formData.append("message", document.querySelector("#mensagemMSG").value)
    formData.append("contacto", true)

    axios
      .post("https://carreiradentalclinic.pt/form-contact.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then(res =>
        res.data === "success"
          ? success()
          : setResponse(res.data)
      )
  }

  const success = () => {
    setResponse("Mensagem enviada com sucesso!")
    fbTrack("trackCustom", "Envio de formulário - Página de Contacto")
    if (typeof window !== "undefined") {
      window.gtag("event", "form_contacto", {
        form: "Formulário de Contacto"
      })
    }
  }

  return (
    <StyledMapa id={id}>
      <div className="mapa">
        <iframe
          src="https://snazzymaps.com/embed/185031"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          title="Mapa"
        ></iframe>
      </div>

      <div className="form">
        <p className={font.rB + " formTitle"}>
          CONTACTE-NOS
          <span className="smallUnderline"></span>
        </p>
        <form
          method="POST"
          name="contacto"
          action="contactar.php"
          id="contactForm"
        >
          <p className={font.rSB + " label"}>NOME*</p>
          <input type="text" name="nomeMSG" id="nomeMSG" className={font.rSB} />
          <br />
          <p className={font.rSB + " label"}>EMAIL*</p>
          <input
            type="text"
            name="emailMSG"
            id="emailMSG"
            className={font.rSB}
          />
          <br />
          <p className={font.rSB + " label"}>TELEFONE*</p>
          <input
            type="text"
            name="telefoneMSG"
            id="telefoneMSG"
            className={font.rSB}
          />
          <br />
          <p className={font.rSB + " label"}>ASSUNTO</p>
          <input
            type="text"
            name="assuntoMSG"
            id="assuntoMSG"
            className={font.rSB}
          />
          <br />
          <p className={font.rSB + " label"}>MENSAGEM</p>
          <textarea
            name="mensagem"
            form="contacto"
            id="mensagemMSG"
            className={font.rSB}
          ></textarea>
        </form>
        <p className={font.rR + " disclaimer"}>
          Este formulário de contacto corresponde ao procedimento normal de
          envio de um email diretamente para a nossa caixa de correio. Os seus
          dados não são armazenados nem utilizados para qualquer outro fim.
        </p>
        <Button
          type="submit"
          form="contactForm"
          name="submit"
          value="submit"
          className={font.rSB + " button btnEnviar"}
          onClick={doSubmit}
        >
          Enviar
        </Button>
        <div className={font.rM + " response"} id={response === "Mensagem enviada com sucesso!" && "contacto-sucesso"}>{response}</div>
      </div>
    </StyledMapa>
  )
}

export default Mapa

const StyledMapa = styled.div`
  margin-bottom: 70px;
  .mapa {
    height: calc(350px + 250 * (100vw - 320px) / 448);
    width: 100%;
  }
  .form {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2% 3% 2% 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 8vw;
  }
  .formTitle {
    font-size: calc(18px + 14 * (100vw - 320px) / 448);
    letter-spacing: 0.1em;
    margin: 0 auto 2em;
    position: relative;
    width: fit-content;
  }
  .label {
    font-size: calc(12px + 2 * (100vw - 320px) / 448);
    letter-spacing: 0.2em;
    margin-bottom: 1%;
    margin-left: 10%;
  }
  .form input[type="text"] {
    outline: none;
    border: solid #b7a99a;
    width: 80%;
    margin-bottom: 2%;
    margin-left: 10%;
    font-size: calc(14px + 10 * (100vw - 320px) / 448);
    height: 2.5em;
    line-height: 2.5em;
  }
  #mensagemMSG {
    outline: none;
    border: solid #b7a99a;
    margin-bottom: 2%;
    margin-left: 10%;
    width: 80%;
    height: 7em;
    font-size: calc(14px + 10 * (100vw - 320px) / 448);
    resize: none;
  }
  .disclaimer {
    font-size: calc(9px + 9 * (100vw - 320px) / 448);
    margin-bottom: 4%;
    color: #808080;
    width: 80%;
    margin-left: 10%;
  }
  .btnEnviar {
    height: 2.5em;
    width: 80%;
    margin-left: 10%;
    padding: 0 calc(40px + 30 * (100vw - 769px) / 1151);
    background-color: #b7a99a;
    color: #ffffff;
    border: none;
    line-height: 2em;
    font-size: calc(14px + 8 * (100vw - 320px) / 448);
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .smallUnderline {
    width: 2em;
    height: 0.25em;
    background-color: #b7a99a;
    position: absolute;
    top: 1.3em;
    left: 0;
  }
  .response {
    width: 80%;
    margin: auto;
    font-size: calc(14px + 14 * (100vw - 320px) / 448);
  }
`
